<template>
  <div
    class="text-style--body2"
    :class="setTextColor()"
    @click="$emit('click')"
  >
    <slot></slot>
  </div>
</template>

<script setup lang="ts">
import { useTypography } from '@Composables/typography';
const props = defineProps({
  dark: Boolean,
  error: Boolean,
  light: Boolean,
  lowDark: Boolean,
  mediumDark: Boolean,
  primary: Boolean
});

const { getTextClassName } = useTypography(props);

function setTextColor() {
  return getTextClassName();
}
</script>

<style scoped lang="scss">
@import '@Assets/scss/components/typography.scss';
</style>
